import PropTypes from 'prop-types'
function Button({ children, btnDisabled,version, type }) {
    return (
        <button type={type} disabled={btnDisabled} className={`btn btn-${version}`}> {children} </button>
    )
}
Button.propTypes = {
    btnDisabled: PropTypes.bool,
    version: PropTypes.string,
    children: PropTypes.node.isRequired,
    type: PropTypes.string
}
Button.defaultProps = {
    btnDisabled: false,
    version: 'primary',
}
export default Button
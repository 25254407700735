import { createContext, useState, useEffect } from "react"
import { v4 as uuidv4 } from 'uuid';

const FeedbackContext = createContext()

export const FeedbackProvider = ({ children }) => {
    const [feedback, setFeedback] = useState([])

    const [feedbackEdit, setFeedbackEdit] = useState({
        item: {},
        edit: false,
    })

    useEffect(()=>{
        fetchFeedback()
    }, [])
    const fetchFeedback = async () => {
        const response = await fetch(`http://localhost:5000/feedback`)
        const data = await response.json()
        setFeedback(data)
    }
    //add feedback
    const addFeedback = (newFeedback) => {
        newFeedback.id = uuidv4();
        setFeedback([newFeedback, ...feedback]);
    }
    // delete feeback by ID
    const deleteFeedback = (id) => {
        if (window.confirm('Are you certain you want to remove this feedback?')) {
            setFeedback(feedback.filter((item) => item.id !== id));
        }
    }
    // set feedback to be edited
    const editFeedback = (item) => {
        setFeedbackEdit({
            item, edit: true
        })
    }

    const updateFeedback = (id, updItem) => {
        if (feedbackEdit === null) return;
        else {
            setFeedback(feedback.map((item) => (item.id === id ? { ...item, ...updItem } : item)))
        }
    }

    return <FeedbackContext.Provider value={{ feedback, feedbackEdit, deleteFeedback, addFeedback, editFeedback, updateFeedback }}>
        {children}
    </FeedbackContext.Provider>
}

export default FeedbackContext
import Card from '../components/shared/Card';
import { Link } from 'react-router-dom';
function AboutPage() {
  return (
    <Card>
      <div className='about'>
        <h1>About This Project</h1>
        <p>This is the about page. It just needs to exist, not look terribly pretty</p>
        <p>Version: 0.0.1rc</p>
      <p><Link to='/'>Home</Link></p>
      </div>
    </Card>
  );
}

export default AboutPage;
